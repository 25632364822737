import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login';
import { NoContentComponent } from './no-content';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'race2024' },
  { path: 'race2024', component: LoginComponent },
  { path: 'upload', loadChildren: () => import('./upload/upload.module').then( (m) => m.UploadModule) },
  { path: 'register', loadChildren: () => import('./register/register.module').then( (m) => m.RegisterModule) },
  // { path: 'about', loadChildren: () => import('./about/about.module').then( (m) => m.AboutModule) },
  { path: 'contact', loadChildren: () => import('./contact/contact.module').then( (m) => m.ContactModule) },
  { path: 'home', loadChildren: () => import('./home/home.module').then( (m) => m.HomeModule) },
  { path: 'resetpassword', loadChildren: () => import('./resetpassword/resetpassword.module').then( (m) => m.ResetPasswordModule) },
  { path: 'forgotpassword', loadChildren: () => import('./forgotpassword/forgotpassword.module').then( (m) => m.ForgotPasswordModule) },
  { path: 'race2024/account', loadChildren: () => import('./user/profileupdate/account.module').then( (m) => m.AccountModule) },
  { path: 'event', loadChildren: () => import('./user/abstract/abstract.module').then( (m) => m.AbstractSubmissionModule) },
  { path: 'abstract', loadChildren: () => import('./workflow/workflow/workflow.module').then( (m) => m.WorkflowModule) },
  { path: 'invitation', loadChildren: () => import('./invite/invite.module').then( (m) => m.InviteModule) },
  { path: 'mytasks', loadChildren: () => import('./home/components/task.module').then( (m) => m.TaskModule) },
  { path: 'ckeditor', loadChildren: () => import('./ckeditor-fullscreen-view/ckeditorfullscreen.module').then( (m) => m.CkEditorFullScreenModule) },
  { path: 'race2024/admin', loadChildren: () => import('./admin/admin.module').then( (m) => m.AdminModule) },
  { path: '**', component: NoContentComponent},
];
@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(routes)],
})
export class AppRoutingModule { }


import { Routes } from '@angular/router';
import { LoginComponent } from './login';
import { NoContentComponent } from './no-content';
const ɵ0 = () => import("./upload/upload.module.ngfactory").then((m) => m.UploadModuleNgFactory), ɵ1 = () => import("./register/register.module.ngfactory").then((m) => m.RegisterModuleNgFactory), ɵ2 = () => import("./contact/contact.module.ngfactory").then((m) => m.ContactModuleNgFactory), ɵ3 = () => import("./home/home.module.ngfactory").then((m) => m.HomeModuleNgFactory), ɵ4 = () => import("./resetpassword/resetpassword.module.ngfactory").then((m) => m.ResetPasswordModuleNgFactory), ɵ5 = () => import("./forgotpassword/forgotpassword.module.ngfactory").then((m) => m.ForgotPasswordModuleNgFactory), ɵ6 = () => import("./user/profileupdate/account.module.ngfactory").then((m) => m.AccountModuleNgFactory), ɵ7 = () => import("./user/abstract/abstract.module.ngfactory").then((m) => m.AbstractSubmissionModuleNgFactory), ɵ8 = () => import("./workflow/workflow/workflow.module.ngfactory").then((m) => m.WorkflowModuleNgFactory), ɵ9 = () => import("./invite/invite.module.ngfactory").then((m) => m.InviteModuleNgFactory), ɵ10 = () => import("./home/components/task.module.ngfactory").then((m) => m.TaskModuleNgFactory), ɵ11 = () => import("./ckeditor-fullscreen-view/ckeditorfullscreen.module.ngfactory").then((m) => m.CkEditorFullScreenModuleNgFactory), ɵ12 = () => import("./admin/admin.module.ngfactory").then((m) => m.AdminModuleNgFactory);
const routes = [
    { path: '', pathMatch: 'full', redirectTo: 'race2024' },
    { path: 'race2024', component: LoginComponent },
    { path: 'upload', loadChildren: ɵ0 },
    { path: 'register', loadChildren: ɵ1 },
    // { path: 'about', loadChildren: () => import('./about/about.module').then( (m) => m.AboutModule) },
    { path: 'contact', loadChildren: ɵ2 },
    { path: 'home', loadChildren: ɵ3 },
    { path: 'resetpassword', loadChildren: ɵ4 },
    { path: 'forgotpassword', loadChildren: ɵ5 },
    { path: 'race2024/account', loadChildren: ɵ6 },
    { path: 'event', loadChildren: ɵ7 },
    { path: 'abstract', loadChildren: ɵ8 },
    { path: 'invitation', loadChildren: ɵ9 },
    { path: 'mytasks', loadChildren: ɵ10 },
    { path: 'ckeditor', loadChildren: ɵ11 },
    { path: 'race2024/admin', loadChildren: ɵ12 },
    { path: '**', component: NoContentComponent },
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12 };
